import React, { useEffect, useContext, useState, createContext } from "react";
import { useLocation } from "react-router-dom";
import "./Questions.css";

import Question from "../Question/Question";

import { AuthContext } from "../../../context/AuthContext";

import LanguageConversion from "../../languageCoverter/LanguageConversion";

import axios from "axios";
import { StartSession } from "../../../apis/services/StartSesionapi";

import FormFooterbtns from "../../FormFooterButtons/FormFooterbtns";
import QuestionTextBox from "../questionTextBox/QuestionTextBox";
import moment from "moment";
import { set } from "date-fns";

export const UserResponseContext = createContext();

export const Questions = ({ view, completed }) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [userresponse, setuserResponse] = useState({});
  const [headingText, setHeadingText] = useState("");
  const [patientName, setPatientName] = useState("");
  const [viewPatienFormDetails, setViewPatienFormDetails] = useState({});

  const [days, setDays] = useState(1);
  const [week, setWeek] = useState(1);
  const [months, setMonths] = useState(1);

  const [patientData, setPatientData] = useState([]);
  const [bodyPainData, setBodyPainData] = useState();
  const [painOptions, setPainOptions] = useState();
  const [tableDataObj, setTableDataObj] = useState();
  const [tableOptions, setTableOptions] = useState();
  const [pastDaysSection, setPastDaysSection] = useState();
  const [pastDaysXvalue, setPastDaysXvalue] = useState();
  const [date, setDate] = useState(moment().format("DD MMMM YYYY"));
  const [data1, setData] = useState([]);
  const token = localStorage.getItem("token1");

  const { LogOut } = useContext(AuthContext);

  const location = useLocation();

  const patientLang = localStorage.getItem("patientLang").toLocaleLowerCase();
  const practitionarLang = localStorage
    .getItem("practitionerLang")
    .toLocaleLowerCase();

  useEffect(() => {
    const getQuestionsData = async () => {
      try {
        const response = await StartSession();
        if (response.data.status) {
          console.log("Questions From Server", response);
          const responseData = response?.data?.data;
          const genderQues = responseData.splice(29, 1);
          const howLongQues = responseData.splice(28, 1);
          responseData.splice(4, 0, genderQues[0]);
          responseData.splice(11, 0, howLongQues[0]);

          const heading = responseData.find((item) => item.id === 32);
          setHeadingText(heading[view ? practitionarLang : patientLang]);

          let tableOptionsObj = responseData.find((item) => item.id === 14);
          tableOptionsObj = tableOptionsObj.options[0];
          tableOptionsObj =
            tableOptionsObj[view ? practitionarLang : patientLang].split(
              "\r\n"
            );
          setTableOptions(tableOptionsObj);

          responseData.pop();
          setData(responseData);
          response?.data?.data.forEach((item, index) => {
            return (userresponse[item.id] = 0);
          });
          console.log(userresponse);
        }
      } catch (err) {
        console.log(err);
        if (err?.response?.status === 401) {
          LogOut();
        }
      }
    };
    getQuestionsData();
  }, [refetch]);

  const gotoparent = (response, quesId) => {
    // console.log(response, quesId);
    setuserResponse({ ...userresponse, [quesId]: response });
  };

  const SubmitForm = () => {
    // console.log("submittingForm", userresponse);
    setShowOverlay(true);

    var data = {
      name: patientName,
      lang: practitionarLang,
      gender: "male",
      age: moment().year() - moment(userresponse[4]).year(),
      user_response: userresponse,
    };
    console.log("DATA", data);
    var config = {
      method: "post",
      url: "https://admin.emtranpro.com/api/session",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        console.log("submittingForm", response);
      })
      .catch(function (error) {
        console.log("submittingForm", error);
        if (error?.response?.status === 401) {
          LogOut();
        }
      });
  };

  useEffect(() => {
    const getresponses = () => {
      var config = {
        method: "get",
        url: `https://admin.emtranpro.com/api/session/historyById/${location?.state?.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      axios(config)
        .then(function (response) {
          console.log("FORMRESPONSE", response);
          const responseData = response?.data?.data;
          const genderQues = responseData.splice(29, 1);
          const howLongQues = responseData.splice(28, 1);
          responseData.splice(4, 0, genderQues[0]);
          responseData.splice(11, 0, howLongQues[0]);

          const nameRes = responseData.find((item) => item.question_id === 3);

          const dateRes = responseData.find((item) => item.question_id === 4);

          const painScaleRes = responseData.find(
            (item) => item.question_id === 8
          );

          const sliderResponse = responseData.find(
            (item) => item.question_id === 23
          );

          const bodyPain = responseData.find((item) => item.question_id === 10);

          const tableDataRes = responseData.find(
            (item) => item.question_id === 14
          );

          const howLongAgoRes = responseData.find(
            (item) => item.question_id === 30
          );

          setViewPatienFormDetails({
            ...viewPatienFormDetails,
            nameRes: nameRes?.option_response,
            dateRes: dateRes?.option_response,
            painScaleRes: painScaleRes?.option_response,
            // howLongAgoRes: howLongAgoRes?.option_response,
          });

          let bodyPainObj = bodyPain?.option_response?.params;
          if (bodyPainObj) {
            bodyPainObj = JSON.parse(bodyPain?.option_response?.params);
          }

          let painOptionsObj = bodyPain?.option_response?.optionSelected;
          if (painOptionsObj) {
            painOptionsObj = JSON.parse(
              bodyPain?.option_response?.optionSelected
            );
          }

          let tableDataResObj = tableDataRes?.option_response;
          if (tableDataResObj) {
            tableDataResObj = JSON.parse(tableDataRes?.option_response);
          }

          const howLongAgoResJSON = howLongAgoRes?.option_response;
          if (howLongAgoResJSON) {
            const howLongAgoObj = JSON.parse(howLongAgoResJSON);
            if (howLongAgoObj.section) {
              setPastDaysSection(howLongAgoObj.section);
            }
            if (howLongAgoObj.xValue) {
              setPastDaysXvalue(howLongAgoObj.xValue);
            }
          }

          const howLongAgoXvalue = howLongAgoRes?.option_response?.xValue;
          if (howLongAgoXvalue) {
            howLongAgoXvalue = JSON.parse(
              howLongAgoRes?.option_response?.xValue
            );
          }
          // console.log(painOptionsObj);

          setBodyPainData(bodyPainObj);
          setPainOptions(painOptionsObj);
          setTableDataObj(tableDataResObj);

          setMonths(sliderResponse.option_response[0]);
          setWeek(sliderResponse.option_response[3]);
          setDays(sliderResponse.option_response[6]);

          setPatientData(responseData);
        })
        .catch(function (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            LogOut();
          }
        });
    };
    if (view) getresponses();
  }, []);
  // console.log("userResponse", userresponse);
  // console.log(data1);
  // console.log(headingText);
  // console.log(patientData);
  return (
    <UserResponseContext.Provider
      value={{
        userresponse,
        setuserResponse,
        patientName,
        setPatientName,
        date,
        setDate,
        days,
        setDays,
        week,
        setWeek,
        months,
        setMonths,
      }}
    >
      <div className="questions">
        {/* {showOverlay && <SubmitForm />} */}

        <div className="questionsWrapper">
          <LanguageConversion flag={false} />
          <p className="startingLine">
            {/* Hello, I am here to help. I do not speak your language, to help me
            help you better please answer as many questions as you can below. If
            you are a friend or relative please fill out as many questions below
            as possible for the person. */}
            {headingText}
          </p>
          <div className="marginBottom"></div>
          <div className="question">
            {data1.map((each, index) => {
              let options =
                each?.options[0]?.[
                  completed || view ? practitionarLang : patientLang
                ].split(",");

              return (
                <>
                  {" "}
                  <Question
                    gotoparent={gotoparent}
                    index={each?.id}
                    radioBtnOption={
                      view
                        ? options?.at(patientData[index]?.option_response)
                        : options?.at(0)
                    }
                    viewPatienFormDetails={viewPatienFormDetails}
                    bodyPainData={bodyPainData}
                    painOptions={painOptions}
                    tableDataObj={tableDataObj}
                    tableOptions={tableOptions}
                    section={pastDaysSection}
                    xValue={pastDaysXvalue}
                    view={location?.state?.view}
                    options={options}
                    quesId={each?.id}
                    ques={
                      each?.[completed || view ? practitionarLang : patientLang]
                    }
                  />
                  {/* {index === 1 && (
                    <QuestionTextBox
                      gotoparent={gotoparent}
                      quesId={each.id + 1}
                    />
                  )} */}
                </>
              );
            })}

            <FormFooterbtns
              completed={completed}
              refetch={refetch}
              setRefetch={setRefetch}
              SubmitForm={SubmitForm}
              view={view}
            />
          </div>
        </div>
      </div>
    </UserResponseContext.Provider>
  );
};

// export default Questions;
